
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
// import moment from 'moment'
import AdminModelForm from '@/views/Admin/Brands/components/AdminModelForm.vue'
import Brands from '@/apis/Brands'
// import _ from 'lodash'
// import { UserStatusId } from '@/typings/AdminTyping'

@Component({
  name: 'addModel',
  components: {
    AdminModelForm
  }
}
)

export default class AddModel extends Mixins(TableMixin) {
  simpleTable = true;
  brandId: any = null;
  // ModelDetails = {};
  BrandDetails = {};

  ModelDetails = {
    name: null,
    car_brand_id: null,
    is_car: null,
    is_truck: null,
    new_mileage: null,
    with_mileage: null
  }

  created() {
    this.brandId = this.$router.currentRoute.params.id
    this.fetchDetailsForUpdateCarBrand(this.brandId)
    this.ModelDetails.car_brand_id = this.brandId
    // this.ModelDetails.car_brand_id = this.brandId;
  }

  fetchDetailsForUpdateCarBrand(brandId: number) {
    Brands.fetchDetailsForUpdateCarBrand(brandId)
      .then(({ data }) => {
        this.BrandDetails = data.brandDetails
        // this.ModelDetails = data.brandDetails
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  storeModel() {
    if (this.ModelDetails.is_car === null && this.ModelDetails.is_truck === null) {
      // @ts-ignore
      this.showErrorSystemNotification('Не выставлен тип модели')
      return
    }

    if (this.ModelDetails.new_mileage === null && this.ModelDetails.with_mileage === null) {
      // @ts-ignore
      this.showErrorSystemNotification('Не выставлено состояние модели')
      return
    }

    Brands.storeModel(this.brandId, this.ModelDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Brands' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
