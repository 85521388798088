
import Vue from 'vue'
import Component from 'vue-class-component'
import Axios from 'axios'
import {
  CarBrand,
  BrandDetails,
  ModelDetails
} from '@/typings/AdminTyping'
import { Prop } from 'vue-property-decorator'
import Brands from '@/apis/Brands'

@Component({
  name: 'AdminModelForm'
})

export default class AdminModelForm extends Vue {
  @Prop(Object) ModelDetailsComp!: ModelDetails
  @Prop(Object) BrandDetailsComp!: BrandDetails
}
